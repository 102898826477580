import logo from "./logo.svg";
import "./App.css";
import Grid from "@mui/material/Grid";
import { useState, useEffect, useRef } from "react";
import { cloneDeep } from "lodash";
import axios from "axios";

const NUMBER_OF_COLUMNS = 20;
let ROW_SET_NUMBER = 2;
let patterns = [];
const master_patterns = [
  [
    [10, 99],
    [1, 9],
    [1, 9],
  ],
  [
    [1, 9],
    [10, 99],
    [10, 99],
  ],
  [
    [10, 99],
    [10, 99],
    [10, 99],
  ],
  [
    [10, 99],
    [100, 999],
    [10, 99],
  ],
];
while (ROW_SET_NUMBER > 0) {
  patterns.push(...master_patterns);
  ROW_SET_NUMBER = ROW_SET_NUMBER - 1;
}
function App() {
  const operations = ["+", "-"];
  // const operations = ["+", "-", "x"];
  const [name, setname] = useState("");
  const [allSums, setallSums] = useState([]);
  const [timer, setTimer] = useState(10);
  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;

  const buttonRef = useRef(null);

  useEffect(() => {
    let interval;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
    } else {
      clearInterval(interval);
      // if (buttonRef.current) {
      // 	buttonRef.current.click();
      // }
      handelSubmit();
    }

    return () => {
      clearInterval(interval); // Cleanup interval on unmount
    };
  }, [timer]);

  function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  function isCombinationUsed(array, num1, num2, sign1, sign2, num4) {
    return array.some(
      (obj) =>
        obj.num1 === num1 &&
        obj.num2 === num2 &&
        obj.sign1 === sign1 &&
        obj.sign2 === sign2 &&
        obj.num4 === num4
    );
  }

  function generateAllSums(pattern) {
    const arrayOfObjects = [];

    for (let i = 0; i < NUMBER_OF_COLUMNS; i++) {
      const id = i + 1;

      let num1, num2, num4, sign1, sign2;

      do {
        function randomize_all() {
          num1 = getRandomNumber(pattern[0][0], pattern[0][1]);
          num2 = getRandomNumber(pattern[1][0], pattern[1][1]);
          num4 = getRandomNumber(pattern[2][0], pattern[2][1]);
          sign1 = operations[Math.floor(Math.random() * operations.length)];
          sign2 = operations[Math.floor(Math.random() * operations.length)];
          if (sign1 == "-" && num1 - num2 < 0) {
            randomize_all();
          }
          if (sign2 == "-" && performOperation(num1, sign1, num2) - num4 < 0) {
            randomize_all();
          }
        }
        randomize_all();
      } while (
        isCombinationUsed(arrayOfObjects, num1, num2, sign1, sign2, num4)
      );

      const num3 = null;
      const num5 = null;

      const obj = { id, num1, num2, num3, num4, num5, sign1, sign2 };
      arrayOfObjects.push(obj);
    }

    setallSums((prev) => [...prev, arrayOfObjects]);
  }

  useEffect(() => {
    setallSums([]);
    patterns.forEach((pattern) => {
      generateAllSums(pattern);
    });
  }, []);

  const handleInputChange = (outerIndex, innerIndex, field, value) => {
    const newArray = cloneDeep(allSums);
    newArray[outerIndex][innerIndex][field] = Number(value);
    setallSums(newArray);
  };

  const performOperation = (num1, operation, num2) => {
    switch (operation) {
      case "+":
        return num1 + num2;
      case "-":
        return num1 - num2;
      // case "x":
      //   return num1 * num2;

      default:
        return null;
    }
  };

  const checkAndCountMatches = (arrayOfObjects) => {
    let matchCount = 0;
    let noMatchCount = 0;

    for (const obj of arrayOfObjects) {
      const { num1, num2, num3, num4, num5, sign1, sign2 } = obj;

      const expectedNum3 = performOperation(num1, sign1, num2);
      const expectedNum5 = performOperation(expectedNum3, sign2, num4);

      if (num3 === expectedNum3 && num5 === expectedNum5) {
        matchCount++;
      } else {
        noMatchCount++;
      }
    }

    return { matchCount, noMatchCount };
  };

  const handelSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }

    let totalMatchCount = 0;
    let totalNoMatchCount = 0;

    allSums.forEach((list) => {
      const res = checkAndCountMatches(list);
      totalMatchCount += res.matchCount;
      totalNoMatchCount += res.noMatchCount;
    });

    handleFinalSubmit({ totalMatchCount, totalNoMatchCount });
  };

  const handleFinalSubmit = async (payload) => {
    const res = await axios
      .post("https://api.abacus.computerjagat.org/submitmarks", {
        name,
        rightAnswer: payload.totalMatchCount,
        wrongAnswer: payload.totalNoMatchCount,
      })
      .catch((err) => err.response);

    if (res?.status === 200) {
      alert(res.data.message);
    } else {
      console.log(res);
      alert("Please enter the name and submit");
      // alert("An error occurred while saving the data");
    }
  };

  return (
    <div className="container">
      <Grid container spacing={0}>
        <Grid item xs={86} md={8} sm={12}>
          <div className="form__group">
            <input
              type="text"
              className="form__input"
              id="name"
              placeholder="Student  name"
              required
              name="name"
              value={name}
              onChange={(e) => setname(e.target.value)}
            />
          </div>
        </Grid>
        <Grid item xs={4} md={4} sm={12}>
          <div className="timer">
            {/* <input
							type='text'
							className='form__input'
							id='name'
							placeholder='dd  name'
							// required
							disabled
						/> */}
            Timer: {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
          </div>
        </Grid>
      </Grid>

      <form onSubmit={handelSubmit}>
        {allSums.map((sumList, sumIndex) => (
          <div className="singel_container" key={sumIndex}>
            {sumList.map((e, index) => (
              <div style={{ width: "fit-content" }}>
                <div className="sum">Sum {e.id}</div>
                <div className="singel_box">
                  <div
                    className="middle"
                    style={{
                      justifyContent: "flex-end",
                      padding: "0 4px",
                      marginTop: "5px",
                    }}
                  >
                    {e.num1}
                  </div>
                  <div
                    className="middle"
                    style={{ justifyContent: "flex-end", padding: "0 4px" }}
                  >
                    {e.sign1} {e.num2}
                  </div>
                  <div>
                    <input
                      type="number"
                      className="single_input_box"
                      // value={e.num3 || ''}
                      onChange={(i) =>
                        handleInputChange(
                          sumIndex,
                          index,
                          "num3",
                          i.target.value
                        )
                      }
                      //   required
                    />
                  </div>
                  <div
                    className="middle"
                    style={{ justifyContent: "flex-end", padding: "0 4px" }}
                  >
                    {e.sign2} {e.num4}
                  </div>
                  <div>
                    <input
                      type="number"
                      className="single_input_box"
                      onChange={(i) =>
                        handleInputChange(
                          sumIndex,
                          index,
                          "num5",
                          i.target.value
                        )
                      }
                      //   required
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}

        <div className="submitContainer">
          <button type="submit" className="submitButton">
            Submit
          </button>
        </div>
        <br></br>
      </form>
    </div>
  );
}

export default App;
